<template>
   <v-btn
        @click="onWhatsappClick"
        color="success"
        :loading="loadingWhatsapp"
        fab
    >
        <v-icon>mdi-whatsapp</v-icon>
        <span class="button-label">Suporte</span>
    </v-btn>
</template>

<script>
export default {
    name: 'WhatsappSupportButton',

    data: () => ({
        loadingWhatsapp: false,
    }),

    methods: {
        async onWhatsappClick () {
            const isMobile = !this.$vuetify.breakpoint.smAndUp;
            const link = isMobile ? 'whatsapp://send' : 'https://web.whatsapp.com/send';
            const phoneFallback = '5549988951210';

            try {
                this.loadingWhatsapp = true;
                const response = await this.$http.get('support/whatsapp-contact-number');
                const phone = response.data?.data || phoneFallback;
                window.open(`${link}?phone=${phone}`);
            } catch (error) {
                console.log('Erro ao obter o número do WhatsApp:', error);
                window.open(`${link}?phone=${phoneFallback}`);
            } finally {
                this.loadingWhatsapp = false;
            }
        },
    },
}
</script>

<style scoped>
.button-label {
    color: white;
    position: absolute;
    font-size: 8px;
    top: -15px;
    background: #4caf50;
    padding: 2px;
    border-radius: 5px;
}
</style>
